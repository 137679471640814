.App {
  text-align: center;
}
.App-logo-container{
    width: 260px;
    height: 260px;
    border-radius: 50%;
    padding: 30px;
    background-color: #FFFFFF;
    vertical-align: middle;
    line-height: 220px;
    box-sizing: border-box;
}
.App-logo {
    width: 100%;
    height: auto;
    pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

.App-header {
    background-color: #333333;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #FFFFFF;
    font-weight: 300;
}
.App-header p{
    max-width: 80vw;
}

.App-link, .App-link-logo {
  color: #FFF;
    transition: opacity 0.3s ease;
}
.App-link-logo{
    text-decoration: none;
}
.App-link:hover{
    text-decoration: none;
}
.App-link-logo:hover{
    opacity: 0.7;
}
.App-link-list{
    list-style: none;
    text-align: left;
    margin-top: 0;
}
.App-link-list li{
    margin-bottom: 20px;
}
.App-video-frame{
    margin: 0 auto 30px;
    width: 560px;
    max-width: 90vw;
    height: 220px;
}
@media (min-width: 768px) {
    .App-video-frame {
        height: 315px;
    }
}
/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
